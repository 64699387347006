
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  inject,
  ref,
  SetupContext,
} from 'vue';
import { useStore } from 'vuex';
import { ApmBase } from '@elastic/apm-rum';

/** Interfaces */
import { SavedNegotiationI } from '@/interfaces/accept-conditions.interface';
import { LocationI } from '@/interfaces/auth.interface';
import {
  GetReferenceAndAgreementI,
  PlaceToPayResponseI,
} from '@/interfaces/placetopay.interface';

/** Utilidades */
import {
  closeSwal,
  showSwalError,
  showSwalLoading,
} from '@/utils/sweetalert2-utils';
import { showErrors, isAppleDevice } from '@/utils/general-utils';

/**Composables */
import { useElasticTransactionApm } from '@/composition/useElasticTransactionApm';

export default defineComponent({
  name: 'ModalGratitude',
  emits: ['confirm', 'close'],
  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    savedNegotiationId: {
      type: String,
      required: true,
      default: '',
    },
  },
  components: {
    NewModal: defineAsyncComponent(
      () => import('@/components/shared/NewModal.vue')
    ),
    ModalPaymentChannels: defineAsyncComponent(
      () => import('@/components/shared/ModalPaymentChannels.vue')
    ),
  },
  setup(props, { emit }: SetupContext) {
    const store = useStore();
    const $apm = inject('$apm') as ApmBase;

    const { customTransactioncApm, endTransactionApm } =
      useElasticTransactionApm();
    const showModalPaymentChannels = ref<boolean>(false);
    const iPhoneLink = ref<unknown>({});

    const negotiationDone = computed<SavedNegotiationI>(() => {
      const negotiations: SavedNegotiationI[] =
        store.state.negotiationSave || [];

      return (
        negotiations.find(
          (item) => item.negotiation_id === props.savedNegotiationId
        ) ?? Object.create({})
      );
    });

    const location = computed<LocationI>(() => {
      return store.state.location;
    });

    const authenticatedUser = computed(() => {
      return store?.state?.authUser;
    });

    const getReferenceAndAgreement = computed<GetReferenceAndAgreementI>(() => {
      const paymentInformation = negotiationDone.value?.payment_information;
      return {
        covenant: paymentInformation?.covenants ?? [],
        identification: authenticatedUser.value.identification ?? '',
        obligationId: negotiationDone.value?.negotiation_id ?? '',
        reference: negotiationDone.value?.payment_information?.reference ?? '',
        existCovenantAttr: false,
        isNpl: true,
      };
    });

    const physicalPayment = () => {
      customTransactioncApm($apm, 'click - pago-en-efectivo', 'custom');
      showModalPaymentChannels.value = true;
      endTransactionApm();
    };

    const closeModalPaymentChannels = () => {
      showModalPaymentChannels.value = false;
    };

    const onlinePayment = () => {
      customTransactioncApm($apm, 'click - realizar-pago-online', 'custom');
      showSwalLoading();
      const paymentInformation = negotiationDone.value?.payment_information;
      store
        .dispatch('payFee', {
          document_type: authenticatedUser.value.document_type,
          identification: authenticatedUser.value.identification,
          payment_platform: `placetopay_${paymentInformation?.code_pse}`,
          reference: paymentInformation?.reference,
          description: paymentInformation?.detail,
          amount: paymentInformation?.value,
          ip_address: location.value?.ip ?? '8.8.8.8',
          user_agent:
            location.value?.uag ??
            'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.107 Safari/537.36 Edg/92.0.902.62',
        })
        .then((response: PlaceToPayResponseI) => {
          closeSwal();
          const { attributes, message, success } = response;
          if (success) {
            const { status, processUrl } = attributes;
            if (status?.status?.toLowerCase() === 'ok') {
              isAppleDevice(iPhoneLink.value as HTMLLinkElement, processUrl);
            } else {
              showSwalError(status.message);
            }
          } else {
            showSwalError(message);
          }
        })
        .catch((error) => {
          showErrors(
            error,
            'Se produjo un error al redireccionar a la plataforma de pago online PSE'
          );
        })
        .finally(() => endTransactionApm());
    };

    const close = () => {
      emit('close');
    };

    return {
      close,
      onlinePayment,
      negotiationDone,
      physicalPayment,
      getReferenceAndAgreement,
      showModalPaymentChannels,
      closeModalPaymentChannels,
      iPhoneLink,
    };
  },
});
